/* =================================
------------------------------------
  Divisima | eCommerce Template
  Version: 1.0
 ------------------------------------
 ====================================*/


/*----------------------------------------*/
/* Template default CSS
/*----------------------------------------*/

html,
body {
	height: 100%;
	font-family: 'Josefin Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	color: #111111;
	font-weight: 600;
}

h1 {
	font-size: 70px;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 30px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

p {
	font-size: 14px;
	color: #585858;
	line-height: 1.6;
}

img {
	max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
	outline: none;
}

a:hover,
a:focus {
	text-decoration: none;
	outline: none;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

/*---------------------
  Helper CSS
-----------------------*/

.section-title {
	text-align: center;
}

.section-title h2 {
	font-size: 36px;
}

.set-bg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
}

.spad {
	padding-top: 105px;
	padding-bottom: 105px;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
	color: #fff;
}

/*---------------------
  Commom elements
-----------------------*/

/* buttons */

.site-btn {
	display: inline-block;
	border: none;
	font-size: 14px;
	font-weight: 600;
	min-width: 167px;
	padding: 18px 47px 14px;
	border-radius: 50px;
	text-transform: uppercase;
	background: #f51167;
	color: #fff;
	line-height: normal;
	cursor: pointer;
	text-align: center;
}

.site-btn:hover {
	color: #fff;
}

.site-btn.sb-white {
	background: #fff;
	color: #111111;
}

.site-btn.sb-line {
	background: transparent;
	color: #fff;
	-webkit-box-shadow: inset 0 0 0 1px #fff;
	box-shadow: inset 0 0 0 1px #fff;
}

.site-btn.sb-dark {
	background: #413a3a;
}

.site-btn.sb-dark.sb-line {
	background-color: transparent;
	color: #111111;
	-webkit-box-shadow: inset 0 0 0 1px #111111;
	box-shadow: inset 0 0 0 1px #111111;
}

/* Preloder */

#preloder {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 999999;
	background: #000;
}

.loader {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -13px;
	margin-left: -13px;
	border-radius: 60px;
	animation: loader 0.8s linear infinite;
	-webkit-animation: loader 0.8s linear infinite;
}

@keyframes loader {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
	50% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
		border: 4px solid #673ab7;
		border-left-color: transparent;
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
}

@-webkit-keyframes loader {
	0% {
		-webkit-transform: rotate(0deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
	50% {
		-webkit-transform: rotate(180deg);
		border: 4px solid #673ab7;
		border-left-color: transparent;
	}
	100% {
		-webkit-transform: rotate(360deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
}

.elements-section {
	padding-top: 100px;
}

.el-title {
	margin-bottom: 75px;
}

.element {
	margin-bottom: 100px;
}

.element:last-child {
	margin-bottom: 0;
}

/* Accordion */

.accordion-area {
	margin-top: 50px;
	border-top: 2px solid #e1e1e1;
}

.accordion-area .panel {
	border-bottom: 2px solid #e1e1e1;
}

.accordion-area .panel-link {
	background-image: url("/medias/arrow-down.png");
	background-repeat: no-repeat;
	background-position: right 10px top 30px;
}

.faq-accordion.accordion-area .panel-link,
.faq-accordion.accordion-area .panel-link.active.collapsed {
	padding: 17px 100px 17px 20px;
}

.faq-accordion.accordion-area .panel-link:after {
	right: 44px;
}

.accordion-area .panel-header .panel-link.collapsed {
	background-image: url("/medias/arrow-down.png");
}

.accordion-area .panel-link.active {
	background-image: url("/medias/arrow-up.png");
}

.accordion-area .panel-link.active {
	background-color: transparent;
}

.accordion-area .panel-link,
.accordion-area .panel-link.active.collapsed {
	text-align: left;
	position: relative;
	width: 100%;
	font-size: 14px;
	font-weight: 700;
	color: #414141;
	padding: 0;
	text-transform: uppercase;
	line-height: 1;
	cursor: pointer;
	border: none;
	min-height: 69px;
	background-color: transparent;
	border-radius: 0;
}

.accordion-area .panel-body {
	padding-top: 10px;
}

.accordion-area .panel-body p {
	color: #8f8f8f;
	margin-bottom: 25px;
	line-height: 1.8;
}

.accordion-area .panel-body p span {
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	color: #f51167;
}

.accordion-area .panel-body img {
	margin-bottom: 25px;
}

.accordion-area .panel-body h4 {
	font-size: 18px;
	margin-bottom: 20px;
}

/*------------------
  Header section
---------------------*/

.header-top {
	padding: 18px 0 14px;
}

.site-logo {
	display: inline-block;
}

.header-search-form {
	width: 100%;
	position: relative;
	padding: 0 10px;
}

.header-search-form input {
	width: 100%;
	height: 44px;
	font-size: 14px;
	border-radius: 50px;
	border: none;
	padding: 0 19px;
	background: #f0f0f0;
}

.header-search-form button {
	position: absolute;
	height: 100%;
	right: 18px;
	top: 0;
	font-size: 26px;
	color: #000;
	border: none;
	cursor: pointer;
	background-color: transparent;
}
.user-panel {
	display: flex;
	align-items: center;
	
}

.user-panel .up-item {
	
	font-size: 14px;
}

.user-panel .up-item i {
	font-size: 22px;
}

.user-panel .up-item a {
	font-size: 14px;
	color: #000;
}

.user-panel .up-item:first-child {
	margin-right: 29px;
}

.shopping-card {
	display: inline-block;
	position: relative;
}

.shopping-card span {
	position: absolute;
	top: -4px;
	left: 100%;
	height: 16px;
	min-width: 16px;
	color: #fff;
	font-size: 13px;
	background: #f51167;
	text-align: center;
	border-radius: 30px;
	padding: 0 2px;
	margin-left: -7px;
}

.main-navbar {
	background: #282828;
}

.slicknav_menu {
	display: none;
}

.main-menu {
	list-style: none;
}

.main-menu li {
	display: inline-block;
	position: relative;
}

.main-menu li a {
	display: inline-block;
	font-size: 16px;
	color: #ffffff;
	margin-right: 50px;
	line-height: 1;
	padding: 17px 0;
	position: relative;
}

.main-menu li a .new {
	position: absolute;
	top: -8px;
	font-size: 10px;
	font-weight: 700;
	color: #fff;
	background: #f51167;
	line-height: 1;
	text-transform: uppercase;
	left: calc(50% - 21px);
	padding: 5px 9px 1px;
	border-radius: 15px;
	width: 42px;
}

.main-menu li:hover .sub-menu {
	visibility: visible;
	opacity: 1;
	margin-top: 0;
}

.main-menu li:hover>a {
	color: #f51167;
}

.main-menu .sub-menu {
	position: absolute;
	list-style: none;
	width: 220px;
	left: 0;
	top: 100%;
	padding: 20px 0;
	visibility: hidden;
	opacity: 0;
	margin-top: 50px;
	background: #fff;
	z-index: 99;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-box-shadow: 2px 7px 20px rgba(0, 0, 0, 0.05);
	box-shadow: 2px 7px 20px rgba(0, 0, 0, 0.05);
}

.main-menu .sub-menu li {
	display: block;
}

.main-menu .sub-menu li a {
	display: block;
	color: #000;
	margin-right: 0;
	padding: 8px 20px;
}

.main-menu .sub-menu li a:hover {
	color: #f51167;
}

.nav-switch {
	display: none;
}

/* ----------------
  Features
---------------------*/

.hero-section {
	padding-bottom: 54px;
}

.hero-slider .hs-item {
	position: relative;
	height: 720px;
}

.hero-slider .hs-item span {
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 3px;
	margin-bottom: 5px;
	display: block;
	position: relative;
	top: 50px;
	opacity: 0;
}

.hero-slider .hs-item h2 {
	font-size: 60px;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 10px;
	position: relative;
	top: 50px;
	opacity: 0;
}

.hero-slider .hs-item p {
	font-size: 18px;
	font-weight: 300;
	margin-bottom: 35px;
	position: relative;
	top: 100px;
	opacity: 0;
}

.hero-slider .hs-item .site-btn {
	position: relative;
	top: 50px;
	opacity: 0;
}

.hero-slider .hs-item .sb-line {
	margin-right: 5px;
}

.hero-slider .hs-item .container {
	position: relative;
	padding-top: 170px;
}

.hero-slider .hs-item .offer-card {
	position: absolute;
	right: 0;
	top: 226px;
	width: 162px;
	height: 162px;
	border-radius: 50%;
	background: #f51167;
	text-align: center;
	padding-top: 20px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	opacity: 0;
}

.hero-slider .hs-item .offer-card:after {
	position: absolute;
	content: "";
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	border: 1px solid #f96790;
	left: 5px;
	top: 5px;
	border-radius: 50%;
}

.hero-slider .hs-item .offer-card span {
	font-size: 18px;
	text-transform: lowercase;
	position: relative;
	top: 50px;
	opacity: 0;
}

.hero-slider .hs-item .offer-card h2 {
	font-size: 72px;
	font-weight: 400;
	line-height: 1;
}

.hero-slider .hs-item .offer-card p {
	text-transform: uppercase;
	line-height: 1;
	font-size: 14px;
}

.hero-slider .slider-nav-warp {
	max-width: 1145px;
	bottom: 0;
	margin: -78px auto 0;
}

.hero-slider .slider-nav {
	display: inline-block;
	padding: 0 38px;
	position: relative;
}

.hero-slider .owl-dots {
	display: -ms-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-top: 9px;
}

.hero-slider .owl-dots .owl-dot {
	width: 8px;
	height: 8px;
	background: #fff;
	border-radius: 15px;
	margin-right: 10px;
	opacity: 0.25;
}

.hero-slider .owl-dots .owl-dot.active {
	opacity: 1;
}

.hero-slider .owl-dots .owl-dot:last-child {
	margin-right: 0;
}

.hero-slider .owl-nav button.owl-next,
.hero-slider .owl-nav button.owl-prev {
	font-size: 27px;
	position: absolute;
	color: #fff;
	opacity: 0.5;
	bottom: -20px;
}

.hero-slider .owl-nav button.owl-next {
	right: 0;
}

.hero-slider .owl-nav button.owl-prev {
	left: 0;
}

.hero-slider .owl-item.active .hs-item h2,
.hero-slider .owl-item.active .hs-item span,
.hero-slider .owl-item.active .hs-item p,
.hero-slider .owl-item.active .hs-item .site-btn {
	top: 0;
	opacity: 1;
}

.hero-slider .owl-item.active .hs-item span {
	-webkit-transition: all 0.5s ease 0.2s;
	-o-transition: all 0.5s ease 0.2s;
	transition: all 0.5s ease 0.2s;
}

.hero-slider .owl-item.active .hs-item h2 {
	-webkit-transition: all 0.5s ease 0.4s;
	-o-transition: all 0.5s ease 0.4s;
	transition: all 0.5s ease 0.4s;
}

.hero-slider .owl-item.active .hs-item p {
	-webkit-transition: all 0.5s ease 0.6s;
	-o-transition: all 0.5s ease 0.6s;
	transition: all 0.5s ease 0.6s;
}

.hero-slider .owl-item.active .hs-item .site-btn {
	-webkit-transition: all 0.5s ease 0.8s;
	-webkit-transition: all 0.5s ease 0.8s;
	-o-transition: all 0.5s ease 0.8s;
	transition: all 0.5s ease 0.8s;
}

.hero-slider .owl-item.active .hs-item .offer-card {
	opacity: 1;
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: all 0.5s ease 1s;
	-webkit-transition: all 0.5s ease 1s;
	-o-transition: all 0.5s ease 1s;
	transition: all 0.5s ease 1s;
}

.slide-num-holder {
	float: right;
	z-index: 1;
	color: #fff;
	position: relative;
	font-size: 24px;
	font-weight: 700;
	position: relative;
	margin-top: -22px;
}

.slide-num-holder span:first-child {
	margin-right: 41px;
}

.slide-num-holder:after {
	position: absolute;
	content: "";
	height: 30px;
	width: 1px;
	background: #fff;
	left: 50%;
	top: 0;
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: center;
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg);
}

/* ------------------
  Features section
---------------------*/

.feature {
	text-align: center;
	background: #f8f8f8;
	height: 100%;
}

.feature:nth-child(2) {
	background: #f51167;
}

.feature:nth-child(2) h2 {
	color: #fff;
}

.feature .feature-inner {
	padding: 20px 25px;
	display: -ms-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 100%;
}

.feature .feature-icon {
	display: inline-block;
	margin-right: 15px;
}

.feature h2 {
	font-size: 24px;
	text-transform: uppercase;
	display: inline-block;
}

/* ----------------------
  Latest product section
------------------------*/

.top-letest-product-section {
	padding-top: 70px;
	padding-bottom: 60px;
}

.top-letest-product-section .section-title {
	margin-bottom: 70px;
}

.product-slider .owl-nav {
	position: absolute;
	top: calc(50% - 60px);
	width: 100%;
	left: 0;
}

.product-slider .owl-nav button.owl-next,
.product-slider .owl-nav button.owl-prev {
	color: #a4a4a4;
	font-size: 42px;
	position: relative;
}

.product-slider .owl-nav button.owl-next {
	float: right;
	right: -92px;
}

.product-slider .owl-nav button.owl-prev {
	float: left;
	left: -92px;
}

.product-item .pi-pic {
	position: relative;
	display: block;
	text-align: center;
}

.product-item .tag-new,
.product-item .tag-sale {
	position: absolute;
	right: 16px;
	top: 14px;
	font-size: 10px;
	font-weight: 700;
	color: #fff;
	background: #50e550;
	line-height: 1;
	text-transform: uppercase;
	padding: 5px 9px 1px;
	border-radius: 15px;
	width: 42px;
}

.product-item .tag-sale {
	text-align: center;
	padding: 5px 0px 1px;
	min-width: 65px;
	background: #f51167;
}

.product-item .pi-links {
	width: 100%;
	position: absolute;
	right: 0;
	bottom: 18px;
	z-index: 9;
	padding-right: 15px;
	text-align: right;
}

.product-item .pi-links a {
	display: inline-table;
	width: 36px;
	height: 36px;
	background: #fff;
	border-radius: 60px;
	font-size: 18px;
	line-height: 18px;
	padding-top: 9px;
	overflow: hidden;
	color: #000;
	position: relative;
	-webkit-box-shadow: 1px 0 32px rgba(0, 0, 0, 0.2);
	box-shadow: 1px 0 32px rgba(0, 0, 0, 0.2);
	-webkit-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
	text-align: center;
}

.product-item .pi-links a i {
	display: inline-block;
	color: #000;
}

.product-item .pi-links a.add-card {
	padding-top: 8px;
}

.product-item .pi-links a.add-card span {
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	position: absolute;
	right: 19px;
	top: 20px;
	opacity: 0;
}

.product-item .pi-links a.add-card:hover {
	width: 185px;
	padding: 8px 18px 0;
	text-align: left;
}

.product-item .pi-links a.add-card:hover span {
	opacity: 1;
	top: 10px;
	-webkit-transition: all 0.4s ease 0.3s;
	-o-transition: all 0.4s ease 0.3s;
	transition: all 0.4s ease 0.3s;
}

.product-item .pi-rating i {
	color: #f51167;
}

.product-item .pi-rating i.fa-fade {
	color: #e6e6e6;
}

.product-item .pi-text {
	height: 87px;
}

.product-item .pi-text p {
	margin-bottom: 0;
}

.product-item .pi-text h6 {
	float: right;
	padding-left: 5px;
	overflow: hidden;
	font-weight: 700;
	color: #111111;
}

.product-item .pi-text del {
	line-height: 1.2;
	font-size: 12px;
	float: right;
	padding-left: 40px;
	overflow: hidden;
	font-weight: 700;
	color: #111111;
}

.product-item .pi-text p a {
	font-size: 16px;
	color: #111111;
	margin-bottom: 0;
}

/* -----------------------
  Product filter section
-------------------------*/

.product-filter-section {
	padding-bottom: 60px;
}

.product-filter-section .section-title {
	margin-bottom: 70px;
}

.product-filter-menu {
	list-style: none;
	margin: 0 -10px;
	padding-bottom: 15px;
}

.product-filter-menu li {
	margin: 0 10px 10px;
	display: inline-block;
}

.product-filter-menu li a {
	color: #111111;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	background: #ebebeb;
	display: block;
	width: 100%;
	padding: 10px 34px;
	border-radius: 31px;
}

/* ----------------
  Banner section
---------------------*/

.banner {
	padding: 50px 34px 47px;
	position: relative;
	margin-bottom: 70px;
}

.banner .tag-new {
	position: absolute;
	right: 26px;
	top: 27px;
	font-size: 24px;
	font-weight: 700;
	color: #fff;
	background: #50e550;
	line-height: 1;
	text-transform: uppercase;
	padding: 7px 16px 1px;
	border-radius: 80px;
}

.banner span {
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 3px;
	margin-bottom: 5px;
	display: block;
}

.banner h2 {
	font-size: 48px;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 10px;
	color: #282828;
}

/* ----------------
  Footer section
---------------------*/

.footer-section {
	background: #282828;
	padding-top: 60px;
}

.footer-logo {
	padding-bottom: 60px;
}

.footer-widget {
	margin-bottom: 70px;
	overflow: hidden;
}

.footer-widget h2 {
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
	color: #fff;
	margin-bottom: 45px;
}

.footer-widget p {
	color: #8f8f8f;
}

.footer-widget.about-widget p {
	margin-bottom: 50px;
	letter-spacing: -0.01em;
}

.footer-widget ul {
	list-style: none;
	float: left;
	margin-right: 37px;
}

.footer-widget ul:last-child {
	margin-right: 0;
}

.footer-widget ul li a {
	display: inline-block;
	position: relative;
	padding-left: 20px;
	font-size: 14px;
	color: #8f8f8f;
	margin-bottom: 6px;
}

.footer-widget ul li a:after {
	position: absolute;
	content: "";
	width: 5px;
	height: 5px;
	left: 0;
	top: 8px;
	border: 1px solid #ec105a;
	border-radius: 50%;
	-webkit-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
}

.footer-widget ul li a:hover {
	color: #fff;
}

.footer-widget ul li a:hover:after {
	width: 7px;
	height: 7px;
	top: 6px;
	background: #ec105a;
}

.fw-latest-post-widget .lp-item {
	margin-bottom: 30px;
	display: block;
	overflow: hidden;
}

.fw-latest-post-widget .lp-thumb {
	width: 64px;
	height: 64px;
	float: left;
	margin-right: 22px;
}

.fw-latest-post-widget .lp-content {
	overflow: hidden;
	padding-top: 2px;
}

.fw-latest-post-widget .lp-content h6 {
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	opacity: 0.25;
	color: #717171;
	margin-bottom: 1px;
}

.fw-latest-post-widget .lp-content span {
	display: block;
	font-size: 12px;
	color: #8f8f8f;
	margin-bottom: 4px;
}

.fw-latest-post-widget .lp-content .readmore {
	font-size: 12px;
	color: #f51167;
}

.contact-widget .con-info span {
	float: left;
	color: #f51167;
	margin-right: 15px;
	overflow: hidden;
}

.social-links-warp {
	border-top: 2px solid #3b3535;
	padding: 46px 0;
}

.social-links a {
	margin-right: 60px;
	display: inline-block;
}

.social-links a:last-child {
	margin-right: 0;
}

.social-links a i {
	font-size: 30px;
	color: #d7d7d7;
	float: left;
	margin-right: 19px;
	overflow: hidden;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.social-links a span {
	display: inline-block;
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
	color: #9f9fa0;
	padding-top: 10px;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.social-links a.instagram:hover i {
	color: #2F5D84;
}

.social-links a.google-plus:hover i {
	color: #E04B37;
}

.social-links a.twitter:hover i {
	color: #5abed6;
}

.social-links a.pinterest:hover i {
	color: #CD212D;
}

.social-links a.facebook:hover i {
	color: #39599F;
}

.social-links a.twitter:hover i {
	color: #5abed6;
}

.social-links a.youtube:hover i {
	color: #D12227;
}

.social-links a.tumblr:hover i {
	color: #37475E;
}

.social-links a:hover span {
	color: #fff;
}

/* --------------
  Other Pages
------------------*/

.page-top-info {
	background: #f8f7f7;
	padding: 60px 0 70px;
}

.page-top-info h4 {
	color: #414141;
	font-weight: 700;
	text-transform: uppercase;
}

.site-pagination {
	font-size: 14px;
	font-weight: 600;
	color: #414141;
}

.site-pagination a {
	display: inline-block;
	font-size: 14px;
	color: #414141;
}

/* --------------
  Category page
------------------*/

.filter-widget {
	margin-bottom: 50px;
}

.filter-widget .fw-title {
	font-size: 18px;
	font-weight: 700;
	color: #414141;
	text-transform: uppercase;
	margin-bottom: 15px;
}

.filter-widget input + label {
	margin-left: 16px;
}

.category-menu {
	list-style: none;
}

.category-menu li a {
	display: block;
	position: relative;
	font-size: 12px;
	color: #414141;
	border-bottom: 1px solid #ebebeb;
	padding: 12px 0 5px 20px;
}

.category-menu li a span {
	float: right;
}

.category-menu li a:after {
	position: absolute;
	content: "";
	width: 9px;
	height: 9px;
	left: 0;
	top: 13px;
	border: 1px solid #f51167;
	border-radius: 50%;
}

.category-menu li a:hover {
	color: #f51167;
}

.category-menu li a:hover:after {
	background: #f51167;
}

.category-menu li a:last-child a {
	margin-bottom: 0;
}

.category-menu li .sub-menu {
	list-style: none;
	overflow: hidden;
	height: 0;
	-webkit-transform: rotateX(90deg);
	transform: rotateX(90deg);
	opacity: 0;
	-webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
	transition: opacity 0.4s, -webkit-transform 0.4s;
	-o-transition: transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s;
}

.category-menu li .sub-menu li a {
	padding-left: 45px;
}

.category-menu li:hover>a {
	color: #f51167;
}

.category-menu li.active>.sub-menu {
	display: block;
	height: auto;
	opacity: 1;
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
}

.price-range-wrap .price-range {
	border-radius: 0;
	margin-right: 13px;
	margin-bottom: 28px;
}

.price-range-wrap .price-range.ui-widget-content {
	border: none;
	background: #ebebeb;
	height: 2px;
}

.price-range-wrap .price-range.ui-widget-content .ui-slider-range {
	background: #ebebeb;
	border-radius: 0;
}

.price-range-wrap .price-range .ui-slider-range.ui-corner-all.ui-widget-header:last-child {
	background: #414141;
}

.price-range-wrap .price-range.ui-widget-content .ui-slider-handle {
	border: none;
	background: #414141;
	height: 14px;
	width: 14px;
	outline: none;
	top: -6px;
	cursor: ew-resize;
	margin-left: 0;
	border-radius: 0;
	border-radius: 20px;
}

.price-range-wrap .price-range .ui-slider-handle.ui-corner-all.ui-state-default span {
	position: absolute;
	font-size: 14px;
	top: 35px;
}

.price-range-wrap .range-slider {
	color: #444444;
	margin-top: 22px;
}

.price-range-wrap {
	border-bottom: 2px solid #ebebeb;
	padding-bottom: 40px;
	margin-bottom: 50px;
}

.price-range-wrap h4 {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	color: #414141;
	margin-bottom: 45px;
}

.price-range-wrap .range-slider .price-input input {
	color: #444444;
	border: none;
	outline: none;
	max-width: 80px;
	pointer-events: none;
}

.price-range-wrap .range-slider .price-input input:nth-child(1) {
	float: left;
}

.price-range-wrap .range-slider .price-input input:nth-child(2) {
	float: right;
}

.fw-color-choose,
.fw-size-choose {
	border-bottom: 2px solid #ebebeb;
	padding-bottom: 40px;
	margin-bottom: 50px;
}

.fw-color-choose .cs-item {
	display: inline-block;
	position: relative;
	margin-right: 14px;
}

.fw-color-choose .cs-item:last-child {
	margin-right: 0;
}

.fw-color-choose label {
	width: 26px;
	height: 26px;
	border-radius: 50px;
	background: #333;
	position: relative;
	cursor: pointer;
}

.fw-color-choose label.cs-gray {
	background: #d7d7d7;
}

.fw-color-choose label.cs-orange {
	background: #6f91ff;
}

.fw-color-choose label.cs-yollow {
	background: #6f91ff;
}

.fw-color-choose label.cs-green {
	background: #8fc99c;
}

.fw-color-choose label.cs-purple {
	background: #bc83b1;
}

.fw-color-choose label.cs-blue {
	background: #9ee7f4;
}

.fw-color-choose label span {
	position: absolute;
	width: 100%;
	text-align: center;
	top: 45px;
	font-size: 11px;
	color: #414141;
}

.fw-color-choose input[type=radio] {
	visibility: hidden;
	position: absolute;
}

.fw-color-choose input[type=radio]:checked+label {
	-webkit-box-shadow: 0 0 0 2px #f51167;
	box-shadow: 0 0 0 2px #f51167;
}

.fw-color-choose input[type=radio]:checked+label span {
	color: #b09d81;
}

.fw-size-choose .sc-item {
	display: inline-block;
	position: relative;
	margin-right: 5px;
}

.fw-size-choose label {
	display: inline-block;
	height: 30px;
	min-width: 30px;
	text-align: center;
	font-size: 14px;
	color: #414141;
	font-weight: 500;
	cursor: pointer;
	border-radius: 50px;
	padding: 7px 6px 0;
}

.fw-size-choose input[type=radio] {
	visibility: hidden;
	position: absolute;
}

.fw-size-choose input[type=radio]:checked+label {
	background: #f51167;
	color: #fff;
}

/* --------------
  Product page
------------------*/

.product-section {
	padding-top: 70px;
	padding-bottom: 65px;
}

.back-link {
	padding-bottom: 50px;
}

.back-link a {
	font-size: 12px;
	color: #414141;
}

.product-pic-zoom {
	margin-bottom: 35px;
	cursor: pointer;
}

.product-thumbs-track {
	width: 1200px;
}

.product-thumbs .pt {
	width: 116px;
	height: 116px;
	float: left;
	margin-right: 31px;
	overflow: hidden;
	cursor: pointer;
	position: relative;
}

.product-thumbs .pt:last-child {
	margin-right: 0;
}

.product-thumbs .pt.active:after {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	border: 2px solid #f51167;
	z-index: 1;
}

.product-details .p-title {
	font-size: 18px;
	font-weight: 700;
	color: #414141;
	text-transform: uppercase;
	margin-bottom: 18px;
}

.product-details .p-price {
	font-size: 24px;
	color: #414141;
	font-weight: 700;
	margin-bottom: 20px;
}

.product-details .p-stock {
	font-size: 12px;
	color: #000;
	font-weight: 700;
	color: #414141;
	margin-bottom: 10px;
}

.product-details .p-stock span {
	color: #f51167;
}

.product-details .p-rating {
	margin-bottom: 15px;
}

.product-details .p-rating i {
	color: #f51167;
}

.product-details .p-rating i.fa-fade {
	color: #e6e6e6;
}

.product-details .p-review {
	margin-bottom: 30px;
}

.product-details .p-review a {
	color: #414141;
	font-size: 14px;
	margin-right: 12px;
	margin-left: 12px;
}

.product-details .p-review a:first-child {
	margin-left: 0;
}

.product-details .fw-size-choose {
	border-bottom: none;
	margin-bottom: 30px;
	padding-bottom: 0;
}

.product-details .fw-size-choose p {
	float: left;
	margin-right: 38px;
	text-transform: uppercase;
	font-weight: 700;
	color: #414141;
	padding-top: 10px;
	margin-bottom: 0;
}

.product-details .fw-size-choose label {
	width: 33px;
	height: 33px;
	font-size: 12px;
	border: 2px solid #414141;
}

.product-details .fw-size-choose input[type=radio]:checked+label {
	border: 2px solid #f51167;
}

.product-details .fw-size-choose .disable label {
	border: 2px solid #e1e1e1;
	color: #cacaca;
}

.product-details .site-btn {
	min-width: 190px;
}

.product-details .social-sharing {
	padding-top: 50px;
}

.product-details .social-sharing span {
	color: #d7d7d7;
	margin-right: 23px;
	font-size: 14px;
}

.product-details .social-sharing span:hover {
	color: #414141;
}

.quantity {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 40px;
}

.quantity p {
	float: left;
	margin-right: 15px;
	text-transform: uppercase;
	font-weight: 700;
	color: #414141;
	padding-top: 10px;
	margin-bottom: 0;
}

.quantity .pro-qty {
	width: 94px;
	height: 36px;
	border: 1px solid #ddd;
	padding: 0 15px;
	border-radius: 40px;
	float: left;
}

.quantity .pro-qty .qtybtn {
	width: 15px;
	display: block;
	float: left;
	line-height: 36px;
	cursor: pointer;
	text-align: center;
	font-size: 18px;
	color: #404040;
}

.quantity .pro-qty input {
	width: 28px;
	float: left;
	border: none;
	height: 36px;
	line-height: 40px;
	padding: 0;
	font-size: 14px;
	text-align: center;
	background-color: transparent;
}

.reviews-product-section .p-rating i,
.reviews-product-section .rating-split i {
	color: #f51167;
}

.reviews-product-section .p-rating i.fa-fade,
.reviews-product-section .rating-split i.fa-fade {
	color: #e6e6e6;
}

.reviews-product-section .rating-box {
	display: flex;
	margin-top: 20px;
}

.reviews-product-section .rating-comment {
	margin-left: 20px;
}

.reviews-product-section,
.related-product-section {
	padding-bottom: 70px;
}

.reviews-product-section .section-title h2,
.related-product-section .section-title h2 {
	font-size: 24px;
	margin-bottom: 60px;
}

@media only screen and (max-width: 1200px) {
	.reviews-product-section .rating-box {
		flex-direction: column;
	}

	.reviews-product-section .rating-comment {
		margin-left: 0px;
	}
}

/* ----------------
  Cart page
---------------------*/

.scrollbar {
	margin: 80px auto 0;
	width: 100%;
	height: 7px;
	line-height: 0;
	background: #ececec;
	overflow: hidden;
}

.scrollbar .handle {
	width: 100px;
	height: 100%;
	background: #fff;
	cursor: pointer;
}

.scrollbar .handle .mousearea {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 7px;
	background: #b09d81;
	border-radius: 30px;
}

.cart-table {
	padding: 40px 34px 0;
	background: #f0f0f0;
	border-radius: 27px;
	overflow: hidden;
}

.cart-table h3 {
	font-weight: 700;
	margin-bottom: 37px;
}

.cart-table table {
	width: 100%;
	min-width: 442px;
	margin-bottom: 17px;
}

.cart-table table tr th {
	font-size: 12px;
	font-weight: 400;
	color: #414141;
	text-align: center;
	padding-bottom: 25px;
}

.cart-table table tr th.product-th {
	text-align: left;
}

.cart-table table tr th.size-th {
	padding-right: 70px;
}

.cart-table table tr th.quy-th {
	padding-right: 20px;
}

.cart-table .table tr th.total-th {
	width: 100px;
}

.cart-table .cart-table-warp table tr td {
	padding-top: 25px;
}

.cart-table .product-col img {
	display: table-cell;
	vertical-align: middle;
	float: left;
	width: 73px;
}

.cart-table .product-col .pc-title {
	display: table-cell;
	vertical-align: middle;
	padding-left: 30px;
}

.cart-table .product-col .pc-title h4 {
	font-size: 16px;
	color: #414141;
	font-weight: 700;
	margin-bottom: 3px;
}

.cart-table .product-col .pc-title p {
	margin-bottom: 0;
	color: #414141;
}

.cart-table .product-col .pc-title del {
	font-size: 12px;
}

.cart-table .quy-col {
	padding-right: 20px;
}

.cart-table .quantity {
	margin-bottom: 0;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.cart-table .quantity .pro-qty {
	width: 80px;
	background: #fff;
	border-color: #fff;
}

.cart-table .quantity .pro-qty .qtybtn {
	width: 10px;
}

.cart-table .size-col,
.cart-table .total-col {
	text-align: center;
}

.cart-table .size-col p,
.cart-table .total-col p {
	color: #414141;
	font-weight: 400;
}

.cart-table .size-col h4 {
	padding-right: 70px;
}

.cart-table .total-cost {
	background: #f51167;
	margin: 0 -34px;
	text-align: right;
	padding: 22px 0;
	padding-right: 50px;
}

.cart-table .total-cost {
	font-size: 18px;
	color: #fff;
}

.card-right .site-btn {
	margin-bottom: 14px;
	width: 100%;
	min-height: 57px;
	padding: 23px 47px 14px;
}

.promo-code-form {
	position: relative;
	margin-bottom: 14px;
}

.promo-code-form input {
	width: 100%;
	height: 58px;
	border: 2px solid #f0f0f0;
	padding-left: 24px;
	padding-right: 100px;
	font-size: 16px;
	border-radius: 80px;
}

.promo-code-form button {
	position: absolute;
	right: 24px;
	top: 0;
	height: 100%;
	background-color: transparent;
	border: none;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 700;
	color: #f51167;
	cursor: pointer;
}

@media only screen and (max-width: 767px) {
	.cart-table {
		padding: 40px 5px 0;
	}

	.cart-table table {
		min-width: 300px;
	}

	.cart-table .product-col img {
		width: 30px;
		max-width: unset;
	}

	.cart-table .product-col .pc-title {
		padding-left: 10px;
	}
}

/* ----------------
  Checkout Page
---------------------*/

.checkout-form .cf-title {
	font-size: 16px;
	font-weight: 700;
	color: #fff;
	line-height: 1;
	border-radius: 50px;
	background: #3b3b3b;
	padding: 21px 29px 20px;
	margin-bottom: 33px;
}

.checkout-form p {
	font-size: 16px;
	color: #414141;
}

.checkout-form h4 {
	font-size: 18px;
	color: #414141;
}

.checkout-form .field {
	width: 100%;
	height: 44px;
	border: none;
	padding: 8px 10px;
	background: #f0f0f0;
	border-radius: 40px;
	margin-bottom: 20px;
	font-size: 14px;
}

.checkout-form textarea {
	height: 88px;
	border-radius: 20px;
}

.checkout-form .address-inputs {
	margin-bottom: 54px;
}

.address-rb {
	text-align: right;
	margin-bottom: 30px;
}

.address-rb .cfr-item {
	display: inline-block;
}

.cf-radio-btns .cfr-item {
	margin-bottom: 15px;
}

.cf-radio-btns label {
	display: block;
	font-size: 16px;
	color: #414141;
	margin-bottom: 0;
	padding-left: 30px;
	position: relative;
	cursor: pointer;
}

.cf-radio-btns label:after {
	position: absolute;
	content: "";
	width: 5px;
	height: 5px;
	left: 4px;
	top: 8px;
	background: #414141;
	border-radius: 50%;
	opacity: 0;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.cf-radio-btns label:before {
	position: absolute;
	content: "";
	width: 13px;
	height: 13px;
	left: 0;
	top: 4px;
	border: 2px solid #e1e1e1;
	border-radius: 40px;
}

.cf-radio-btns input[type=radio] {
	visibility: hidden;
	position: absolute;
}

.cf-radio-btns input[type=radio]:checked+label:after {
	opacity: 1;
}

.shipping-btns {
	margin-bottom: 50px;
}

.shipping-btns .cf-radio-btns label {
	font-size: 18px;
	font-weight: 600;
	padding-left: 37px;
}

.payment-list {
	list-style: none;
	margin-bottom: 40px;
}

.payment-list li {
	font-size: 18px;
	font-weight: 600;
	color: #414141;
	margin-bottom: 20px;
}

.payment-list li a,
.payment-list li span {
	padding-left: 40px;
}

.submit-step {
	margin-top: 20px;
	margin-bottom: 20px;
}

.submit-order-btn {
	width: 100%;
	min-height: 58px;
}

.checkout-cart {
	background: #f0f0f0;
	padding: 40px 24px 30px;
	border-radius: 25px;
}

.checkout-cart h3 {
	margin-bottom: 30px;
}

.checkout-cart .product-list {
	list-style: none;
}

.checkout-cart .product-list li {
	overflow: hidden;
	display: block;
	margin-bottom: 29px;
}

.checkout-cart .product-list .pl-thumb {
	float: left;
	overflow: hidden;
	margin-right: 22px;
	width: 99px;
}

.checkout-cart .product-list .pl-thumb img {
	min-width: 100%;
}

.checkout-cart .product-list h6 {
	font-weight: 700;
	color: #414141;
	padding-top: 15px;
	margin-bottom: 5px;
}

.checkout-cart .product-list p {
	font-size: 16px;
	margin-bottom: 0;
}

.checkout-cart .price-list {
	padding-left: 17px;
	padding-right: 5px;
	list-style: none;
}

.checkout-cart .price-list li {
	overflow: hidden;
	display: block;
	font-size: 18px;
	color: #414141;
	margin-bottom: 10px;
}

.checkout-cart .price-list li span {
	float: right;
	/*width: 60px;*/
	text-align: left;
}

.checkout-cart .price-list li.total {
	padding-top: 35px;
	font-weight: 700;
}

/* ----------------
  Contact Page
---------------------*/

.contact-section {
	padding-top: 80px;
	padding-bottom: 0;
	position: relative;
}

.contact-info h3 {
	margin-bottom: 50px;
}

.contact-social {
	display: -ms-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 85px;
	padding-top: 20px;
}

.contact-social a {
	display: -ms-inline-flex;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	width: 32px;
	height: 32px;
	background: #f0f0f0;
	color: #414141;
	font-size: 14px;
	border-radius: 50%;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-right: 12px;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
}

.contact-social a:hover {
	color: #fff;
	background: #f51167;
}

.contact-form input,
.contact-form textarea {
	width: 100%;
	height: 44px;
	border: none;
	padding: 0 18px;
	background: #f0f0f0;
	border-radius: 40px;
	margin-bottom: 17px;
	font-size: 14px;
}

.contact-form textarea {
	padding-top: 16px;
	border-radius: 18px;
	height: 175px;
	margin-bottom: 32px;
}

.map {
	position: absolute;
	width: calc(50% - 15px);
	height: 100%;
	right: 0;
	top: 0;
	background: #ddd;
}

.map iframe {
	width: 100%;
	height: 100%;
}

/* ----------------
  Responsive
---------------------*/

@media (min-width: 1200px) {
	.container {
		max-width: 1175px;
	}
}

@media (max-width: 1350px) {
	.product-slider .owl-nav {
		position: relative;
		left: 0;
		top: 0;
		text-align: center;
		padding-top: 20px;
	}
	.product-slider .owl-nav button.owl-prev,
	.product-slider .owl-nav button.owl-next {
		float: none;
		left: 0;
		right: 0;
		margin: 0 10px;
	}
}

/* Medium screen : 992px. */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.hero-slider .slider-nav-warp {
		max-width: 930px;
	}
	.footer-widget ul {
		margin-right: 5px;
	}
	.social-links a {
		margin-right: 20px;
	}
}

/* Tablet :768px. */

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.site-logo {
		margin-bottom: 20px;
	}
	.header-search-form {
		margin-bottom: 15px;
	}
	.user-panel {
		text-align: center;
	}
	.main-menu {
		text-align: center;
	}
	.sub-menu {
		text-align: left;
	}
	.main-menu li a {
		margin-right: 30px;
	}
	.hero-slider .slider-nav-warp {
		max-width: 690px;
	}
	.hero-slider .hs-item .offer-card {
		top: 20px;
	}
	.feature h2 {
		font-size: 18px;
	}
	.product-filter-menu {
		text-align: center;
	}
	.product-filter-menu li {
		margin: 0 5px 10px;
	}
	.social-links {
		text-align: center;
	}
	.social-links a {
		margin-right: 20px;
	}
	.social-links a span {
		display: none;
	}
	.cart-table,
	.checkout-cart,
	.product-thumbs {
		margin-bottom: 50px;
	}
	.map {
		position: relative;
		width: 100%;
		background: #ddd;
		height: 400px;
		margin-top: 70px;
	}
}

/* Large Mobile :480px. */

@media only screen and (max-width: 767px) {
	.site-logo {
		margin-bottom: 20px;
	}
	.header-search-form {
		margin-bottom: 15px;
	}
	.user-panel {
		text-align: center;
	}
	.main-menu {
		display: none;
	}
	.slicknav_btn {
		background-color: #565656;
	}
	.slicknav_menu {
		background: #282828;
		display: block;
	}
	.slicknav_menu .new {
		font-size: 10px;
		font-weight: 700;
		color: #fff;
		background: #f51167;
		line-height: 1;
		text-transform: uppercase;
		padding: 5px 9px 1px;
		border-radius: 15px;
		width: 42px;
		margin-left: 5px;
	}
	.hero-slider .slider-nav-warp {
		max-width: 510px;
	}
	.hero-slider .hs-item h2 {
		font-size: 50px;
	}
	.hero-slider .hs-item .offer-card {
		display: none;
	}
	.product-filter-menu {
		text-align: center;
	}
	.product-filter-menu li {
		margin: 0 2px 10px;
	}
	.footer-widget ul {
		margin-right: 25px;
	}
	.social-links {
		text-align: center;
	}
	.social-links a {
		margin-right: 15px;
	}
	.social-links a span {
		display: none;
	}
	.cart-table,
	.checkout-cart,
	.product-thumbs {
		margin-bottom: 50px;
	}
	.cart-table .size-col h4,
	.cart-table table tr th.size-th,
	.cart-table table tr th.quy-th,
	.cart-table .quy-col {
		padding-right: 0;
		width: 70px;
	}
	.cart-table .quy-col {
		width: 80px;
	}
	.address-rb {
		text-align: left;
	}
	.map {
		position: relative;
		width: 100%;
		background: #ddd;
		height: 400px;
		margin-top: 70px;
	}
}

/* Medium Mobile :480px. */

@media only screen and (min-width: 576px) and (max-width: 766px) {
	.hero-slider .slider-nav-warp {
		padding: 0 15px;
	}
	.banner .tag-new {
		position: relative;
		display: inline-block;
		margin-bottom: 18px;
		right: 0;
		top: 0;
	}
}

/* Small Mobile :320px. */

@media only screen and (max-width: 479px) {
	.hero-slider .slider-nav-warp {
		max-width: 510px;
		padding: 0 15px;
	}
	.hero-slider .hs-item h2 {
		font-size: 35px;
	}
	.hero-slider .hs-item .sb-line {
		margin-bottom: 15px;
	}
	.section-title h2 {
		font-size: 28px;
	}
	.feature h2 {
		font-size: 18px;
	}
	.banner .tag-new {
		position: relative;
		display: inline-block;
		margin-bottom: 18px;
		right: 0;
		top: 0;
	}
	.social-links {
		text-align: center;
	}
	.social-links a i {
		font-size: 20px;
		margin-right: 0;
	}
	.social-links a span {
		display: none;
	}
}



/* ----------------
  Checkout Page
---------------------*/

.checkout-section .btn_action { display: flex; justify-content: flex-end; }
.checkout-form .box__actions { margin-bottom: 20px; }
.form--coordinates .form__controls-meta { font-size: 12px; }
.form--coordinates .step__actions { margin-bottom: 20px; }


/* ----------------
  Cart success Page
---------------------*/

.cart-success-section .section__title { margin-bottom: 20px; }
.cart-success-section .cart-table { width: 50%; }
.cart-success-section .total-cost { color: #fff; }
.cart-success-section .total-cost .total { font-weight: 700; }
.cart-success-section .total-cost table tr td:first-child { padding-left: 200px; text-align: left; }
.cart-success-section .total-cost table tr td:last-child { text-align: right; }

@media only screen and (max-width: 767px) {
	.cart-success-section .cart-table { width: 100%; }
	.cart-success-section .cart-table table { width: 100%; min-width: unset; }
	.cart-success-section .total-cost table tr td:first-child { padding-left: 34px; }
}


/* ----------------
  Account page
---------------------*/

.account-section .entry__section+.entry__section,
.account-section .entry__section+.entry__actions { margin-top: 20px; }
.account-section .entry__section+.entry__actions { margin-bottom: 40px; }
.account-section .entry__section .list-dots { list-style: none; }

.account-section .form__row input + label { margin-left: 10px; }


/* ----------------
  Account orders Page
---------------------*/

.account-section .order .order__head { cursor: pointer; border-bottom: 1px solid; }
.account-section .order .order__body { display: none; }
.account-section .order.active .order__body { display: block; }
.account-section .order .order__body .cart-table { border-radius: unset; }
.account-section .order .order__body .total-cost { color: #fff; }
.account-section .order .order__body .total-cost .total { font-weight: 700; }
.account-section .order .order__body .total-cost table tr td:first-child { padding-left: 200px; }
.account-section .order .order__body .total-cost table tr td:last-child { text-align: right; }

@media only screen and (max-width: 767px) {
	.account-section .order .order__body .cart-table { width: 100%; }
	.account-section .order .order__body .cart-table table { width: 100%; min-width: unset; }
	.account-section .order .order__body .total-cost table tr td:first-child { padding-left: 34px; }
}


/* ----------------
  Auth page
---------------------*/

.login-section .form__controls input + label { margin-left: 10px; }


/* ----------------
  Blog page
---------------------*/

.blog-section { margin-bottom: 30px; }
.blog-section .ns-article {	margin-top: 30px; }
.blog-section .ns-article .section__title a { color: #111111; }
.blog-section .ns-article-content {	display: flex; }
.article-section .container_img_body { display: inline-block; }
.article-section .ns-article-content img
{
	float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}
.article-section .article-section { margin: 30px 0; }


/* ----------------
  Popup
---------------------*/

.popup__container { padding: 2.2rem; }
.popup__container .product__image { text-align: center; }
.popup__container .product__title { text-align: center; }


/* ----------------
  Product card list
---------------------*/

.product-slider { position: relative; }
.product-slider .list-products { display: flex; justify-content: center; }
.product-slider .list-products .product-item  { margin: 10px; }

.product-slider .slider__arrows {
	position: absolute;
	top: calc(50% - 60px);
	width: 100%;
	left: 0;
}

.product-slider .slider__arrows .slider__arrow { border: none; background-color: #fff; border-radius: 30px; cursor: pointer; }
.product-slider .slider__arrows .slider__arrow--next {
	position: relative;
	color: #a4a4a4;
    font-size: 42px;
	float: right;
	right: -50px;
}

.product-slider .slider__arrows .slider__arrow--prev {
	position: relative;
	color: #a4a4a4;
    font-size: 42px;
	float: left;
	left: -50px;
}

@media only screen and (max-width: 767px) {
	.product-slider .slider__arrows .slider__arrow--next { right: 0; }
	.product-slider .slider__arrows .slider__arrow--prev { left: 0; }
}


/* ----------------
  Cookie banner
---------------------*/

.cookie-banner {
	position: fixed;
	background: #000;
	padding: 10px;
	width: 100%;
    z-index: 10000;
    bottom: 0;
    left: 0;
    right: 0;
}
.cookie-banner p { color: #fff; text-align: center; }
.cookie-banner div { text-align: center; }


/* ----------------
  Others filters
---------------------*/

.category-menu li { position: relative; }
.category-menu li label { cursor: pointer; }
.category-menu li input[type="checkbox"] { display: none; }
.category-menu [type="checkbox"] + label:before { 
	position: absolute;
    content: "";
    width: 9px;
    height: 9px;
    left: 0;
    top: 6px;
    border: 1px solid #f51167;
    border-radius: 50%;
}
.category-menu [type="checkbox"]:checked + label:before {
	background: #f51167;
}


/* ----------------
  Color filter
---------------------*/

.category-menu-color { display: flex; flex-wrap: wrap; list-style: none; }
.category-menu-color li { position: relative; }
.category-menu-color li input[type="checkbox"] { display: none; }
.category-menu-color .color_filter { width: 26px; height: 26px; padding: 0px !important; margin: 0 10px 0 0 !important; border-radius: 13px; border: 1px solid rgba(0,0,0,0.1); cursor: pointer; }
.category-menu-color [type="checkbox"]:checked + .color_filter { box-shadow: 0 0 0 2px #f51167; }


/* ----------------
  Paging
---------------------*/

.paging { justify-content: flex-end; margin-bottom: 10px; }
.paging ul li { padding: 5px 10px; border-radius: 12px; }
.paging ul li.active { background: #f51167; }
.paging ul li a { color: #000; }
.paging ul li.active a { color: white; }


/* ----------------
  Loader
---------------------*/

.loading-indicator:before {
	content: '';
	background: #000000cc;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9998;
  }
  
  @keyframes spinner {
	to {
		transform: rotate(360deg);
	}
  }
  
  .loading-indicator:after {
	content: '';
	box-sizing: border-box;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 50px;
	height: 50px;
	margin-top: -25px;
	margin-left: -25px;
	border-radius: 50%;
	border: 2px solid #282828;
	border-top-color: #f51167;
	border-right-color: #f51167;
	animation: spinner .6s linear infinite;
	z-index: 9999;
  }

  .variants__container {
    padding: 20px 0;
    box-sizing: content-box;
}

.variants__container li {
    list-style: none;
}

.variant-value {
    padding: 5px;
    display: inline-block;
    margin: 5px;
    border: 1px solid black;
}

.variant-value:hover {
    background-color: #576fa1;
}

.variant-value img {
    width: 40px;
    height: 40px;
}

.variant-value.selected {
    background-color: #576fa1;
}

.variants__container select {
    width: 100%;
    border-radius: 0;
    padding: 5px;
    margin-bottom: 5px;
    max-width: 200px;
}
.variants__container .warn-message {
    padding: 8px;
    background-color: orange;
    color: white;
    border-radius: 5px;
    width: fit-content;

}

.variants-values__list > *:hover {
    cursor: pointer;
}
.variants__container .tooltip-list li {
    position: relative;
    display: inline-block;
}
  
/* Tooltip text */
.variants__container .tooltip-list li .tooltiptext {
    visibility: hidden;
    background-color: #eee;
    color: black;
    text-align: center;
    font-size: 15px;
    padding: 5px;
    border-radius: 6px;
    width: auto;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    top: 100%;
    z-index: 1;
}
  
/* Show the tooltip text when you mouse over the tooltip container */
.variants__container .tooltip-list li:hover .tooltiptext {
    visibility: visible;
    transition-delay:0.5s;
}

/* ----------------
  Langage
---------------------*/
.nav-lang { margin-left: 25px; }
.container-nav-lang { min-height: 46px; }

.nav-lang ul {  list-style-type: none; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; margin-bottom: 0; }

.nav-lang li + li { margin-left: 8px; }

.nav-lang button { display: block; line-height: 14px; background: var(--color-theme); border: 1px solid var(--color-theme); padding: 0 1px; min-width: 16px; text-align: center; text-transform: uppercase; font-size: 15px; font-weight: 400; color: rgb(0, 0, 0); text-decoration: none; -webkit-transition: background .4s, color .4s; -o-transition: background .4s, color .4s; transition: background .4s, color .4s; }

.nav-lang button:hover,
.nav-lang .current button { background: white; color: red; }

.btn-lang { display: none; }